



















import { API_OPENED_ORG_INFO } from "@/configs/Apis";
import { Fetcher as Ajax } from "@/utils/Request";
import Vue from "vue";
declare interface MainData {
  imageSrc: string;
  orgInfo: any;
}
export default Vue.extend({
  name: "AuthMainLayout",
  props: {
    userCls: String,
    title: {
      type: String,
      required: true
    }
  },
  data: function(): MainData {
    return {
      imageSrc: "images/login-bg.png",
      orgInfo: {}
    };
  },
  created() {
    this.initInfo();
  },
  methods: {
    initInfo() {
      Ajax.queryData(API_OPENED_ORG_INFO, {}, "GET", false).then(
        (info: any) => {
          this.orgInfo = info || {};
          this.orgInfo.copyright =
            this.orgInfo.copyright ||
            `版权所有© ${new Date().getFullYear()}, www.quan-ip.com. All rights reserved.`;
        }
      );
    }
  }
});
